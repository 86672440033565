import { LatLon as UtmLatLon } from 'geodesy/utm';
import jp from 'jsonpath';
import { cloneDeep, extendWith } from 'lodash';
import { action, computed, makeObservable, observable, override, toJS } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlShape } from 'react-intl';
import { Tail, Winch } from 'src/interfaces/FleetManagerVessel';
import { ConstantTensionWinchProfile } from 'src/interfaces/types';
import { ListFieldValue } from '../components/common/InputField/types';
import { quickFetchScenario } from '../helpers/apiHelper';
import { largeRound, maRound } from '../helpers/common';
import { displayToField } from '../helpers/conversions';
import { PrescreenIndicator } from '../helpers/enums';
import getConfigSection, { getScenariosConfig } from '../helpers/getConfigSection';
import getFieldConfig from '../helpers/getFieldConfig';
import { PrescreeningResult, calculateBerthPrescreening } from '../helpers/ma/berthPrescreening';
import {
  calculateDisplacement,
  calculateFreeboard,
  getVesselMinOfDrafts,
  setDerivedValues,
} from '../helpers/ma/fieldCalculations';
import { lineGroupKeys } from '../helpers/ma/lineNames';
import { calculateBerthMarkers, getHeadingAngle, latLngToUtm } from '../helpers/mapHelpers';
import setDefaultValues from '../helpers/setDefaultValues';
import { validateField } from '../helpers/validators';
import { DataConfig, Diagnostics, Validator } from '../interfaces/Config';
import { Berth, BollardProfile, PortData } from '../interfaces/PortData';
import { ScenarioContainer } from '../interfaces/ScenarioContainer';
import { Fairlead, Line, Point3D, TailProfile, VesselScenarioContainer } from '../interfaces/VesselScenario';
import VesselScenarioBlueprint from '../models/blueprints/VesselScenarioBlueprint';
import { RootStore } from './RootStore';
import { ScenarioStore } from './ScenarioStore';
import { AllBollard, CrossAwarenessScenario, VisibleScenario } from './types';
import { VesselScenarioValidity } from './types/vesselTypes';

export class VesselScenarioStore extends ScenarioStore {
  initialScenarioDataState = {
    id: '',
    dateTime: '',
    version: '',
    lastJobStatus: '',
    data: VesselScenarioBlueprint,
  } as VesselScenarioContainer;

  initialValidityState = {
    valid: true,
    mooring: {
      valid: true,
      proximity: {
        summary: [],
      },
      lines: {
        valid: true,
        lines: [],
      },
    },
  } as VesselScenarioValidity;

  scenarioData = this.initialScenarioDataState;
  blankScenarioData: VesselScenarioContainer = this.initialScenarioDataState;
  originalScenarioData: VesselScenarioContainer = this.initialScenarioDataState;
  closeByScenarios: CrossAwarenessScenario[] = [];

  constructor(allStores: RootStore) {
    super(allStores);

    makeObservable(this, {
      scenarioData: override,
      originalScenarioData: override,
      closeByScenarios: observable,

      extendWithBlueprint: action.bound,
      setScenarioData: action.bound,
      revertScenario: action.bound,
      setScenarioDataField: action.bound,
      resetScenarioDataToDefaults: action.bound,
      handleValueChange: action.bound,
      handleValuesChange: action.bound,
      setCloseByScenarios: action.bound,
      setAllWinchProfiles: action.bound,
      changeWinchId: action.bound,
      setConstantTensionWinchProfile: action.bound,
      getLineCharacteristics: action.bound,
      computeVesselFields: action.bound,
      repairMooringLines: action.bound,
      setDefautlVaryingPretension: action.bound,

      mooringArrangement: computed,
    });
  }

  get mooringArrangement() {
    const val = toJS(
      this.scenarioData &&
        this.scenarioData.data &&
        this.scenarioData.data.mooring &&
        this.scenarioData.data.mooring.mooringArrangementName &&
        this.scenarioData.data.mooring.mooringArrangements
        ? this.scenarioData.data.mooring.mooringArrangements[this.scenarioData.data.mooring.mooringArrangementName]
        : undefined,
    );

    return val;
  }

  extendWithBlueprint(scenarioContext: VesselScenarioContainer) {
    // Merge mapping; As if we add new properties to any of these objects and edit an old scenario,
    // If it is missing the property, the UI will throw an error about controlled components and potentially
    // overlap placeholder labels on textfields when the value is newly created in edit mode.
    const newScenarioData = extendWith(scenarioContext.data, VesselScenarioBlueprint, (objValue, srcValue) => {
      let useBlueprintData = false;

      // If property doesn't exist or property value is null in working scenarioData (take from blueprint)
      if (objValue === undefined || objValue === null) {
        useBlueprintData = true;
      }

      return useBlueprintData ? srcValue : objValue;
    });

    return newScenarioData;
  }

  setScenarioData(
    scenarioContext: VesselScenarioContainer,
    intl: IntlShape,
    freshOpen?: boolean,
    noValidation?: boolean,
    validateProximityOnly = false,
  ): Promise<boolean> {
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');
    const enhancedData = this.extendWithBlueprint(scenarioContext);

    scenarioContext.data = enhancedData;
    this.scenarioData = scenarioContext;

    if (freshOpen) {
      this.originalScenarioData = cloneDeep(this.scenarioData);
      this.upgradeModelIfNecessary(this.scenarioData);
    }

    const promise = new Promise<CrossAwarenessScenario[]>((resolve, reject) => {
      if (this.scenarioData.id || this.allStores.appStateStore.isNewMode) {
        console.log('Updating close by scenarios...');
        resolve(this.setCloseByScenarios(this.scenarioData, diagnosticsConfig, intl));
      } else {
        resolve([]);
      }
    });

    return promise
      .then((closeByScenarios) => {
        if (!noValidation) {
          this.validateCloseByScenarios(closeByScenarios, diagnosticsConfig, intl, validateProximityOnly);
        }

        return true;
      })
      .catch((reason) => {
        console.warn('Could not run setScenarioData()', reason);

        return true;
      });
  }

  upgradeModelIfNecessary = (scenarioData: VesselScenarioContainer) => {
    // General
    scenarioData.validity = scenarioData.validity || {};

    // Version: null -> v1
    if (typeof scenarioData.data.version === 'undefined' || !scenarioData.data.version) {
      scenarioData.data.vessel.lineProfiles?.forEach(
        (profile: any /*purplosely any as it may be a non-conformant model*/) => {
          if (typeof profile.totalBreakingStrength !== 'undefined') {
            profile.minimumBreakingLoad = profile.totalBreakingStrength;
            delete profile.totalBreakingStrength;
          }
        },
      );
      // If we don't have tailProfiles, use lineProfiles in it's place
      if (typeof scenarioData.data.vessel.tailProfiles === 'undefined') {
        scenarioData.data.vessel.tailProfiles = cloneDeep(scenarioData.data.vessel.lineProfiles) as any;
        scenarioData.data.vessel.tailProfiles.forEach((profile: any) => {
          // Clean up if we were cloned from a copy of lineProfiles
          profile.tailProfileId = profile.lineProfileId;
          delete profile.lineProfileId;
          profile.tailProfileName = profile.lineProfileName;
          delete profile.lineProfileName;
          if (typeof profile.totalBreakingStrength !== 'undefined') {
            profile.minimumBreakingLoad = profile.totalBreakingStrength;
            delete profile.totalBreakingStrength;
          }
        });

        Object.keys(scenarioData.data.mooring.mooringArrangements).forEach((key) => {
          scenarioData.data.mooring.mooringArrangements[key].forEach((line: any, index) => {
            // If no tail yet, set it up
            line.tail = line.tail ?? {
              tailProfileId: line.tailProfileId,
              length: line.tailLength,
            };
            delete line.tailProfileId;
            delete line.tailLength;
          });
        });
      }
    } else if (scenarioData.data.version === 1) {
      // For eg. may need to perform particular upgrades for v1 models
    }
  };

  /**
   * Do FULL validation against current scenario and all adjacent scenarios.
   * This is important for eg. as we change bollards moving off max bollard capacity,
   * we may also need to send another scenario green at the same time.
   * @param closeByScenarios
   * @param diagnostics
   * @param intl
   */
  validateCloseByScenarios = (
    closeByScenarios: CrossAwarenessScenario[],
    diagnostics: Diagnostics,
    intl: IntlShape,
    proximityOnly = false,
  ) => {
    // Validate adjacents
    if (closeByScenarios) {
      closeByScenarios
        .filter((f) => f.id !== this.scenarioData.id)
        .forEach((v) => {
          this.allStores.appStateStore.validateVesselScenario(
            v as ScenarioContainer,
            this.getCloseByScenario(v.id).validity,
            diagnostics,
            intl,
            true,
          );
        });
    }

    // Validate primary
    const validity = this.getCloseByScenario(this.scenarioData.id)?.validity;

    if (validity) {
      this.allStores.appStateStore.validateVesselScenario(
        this.scenarioData,
        validity,
        diagnostics,
        intl,
        proximityOnly,
      );
    }
  };

  /**
   * Sets close by scenarios at the berth.
   * - Validate Scenario
   * - Expand Bollard List
   * @param scenario the current scenario
   * @param portStore
   * @param diagnosticsConfig
   * @param vesselScenarioStore
   */
  setCloseByScenarios = (
    scenario: VisibleScenario,
    diagnosticsConfig: Diagnostics,
    intl: IntlShape,
  ): Promise<CrossAwarenessScenario[]> => {
    let closestScenarios = null;
    const scenariosConfig = getScenariosConfig('mooringEditor', this.allStores.appStateStore.authorisedModules);

    if (scenario.data.mooring.berthName) {
      closestScenarios = this.allStores.portStore.getAdjacentScenarios(scenario, diagnosticsConfig);
    }

    this.closeByScenarios.splice(0);
    const crossAwarenessScenario = scenario as CrossAwarenessScenario;
    crossAwarenessScenario.validity = this.initialValidityState;
    this.closeByScenarios.push(crossAwarenessScenario);

    if (closestScenarios) {
      const promises: Promise<CrossAwarenessScenario>[] = [];

      closestScenarios.forEach((closest) => {
        const promise = new Promise<CrossAwarenessScenario>((resolve, reject) =>
          quickFetchScenario(
            this.allStores.appStateStore,
            `${scenariosConfig.scenarioConnection}-${this.allStores.appStateStore.session.customerCode}`,
            closest.id,
            (sce) => {
              const closeByVessel = {
                data: sce,
                id: closest.id,
                distance: closest.distance,
                collision: closest.collision,
                toTheRight: closest.toTheRight,
                validity: this.initialValidityState,
              } as CrossAwarenessScenario;

              this.allStores.vesselScenarioStore.expandBollardList(closeByVessel as any);
              resolve(closeByVessel);
            },
            process.env.REACT_APP_API_MA_ENDPOINT_URL, // Specific MA scenario end point
          ),
        );
        promises.push(promise);
      });

      return Promise.all(promises).then((scenarios: CrossAwarenessScenario[]) => {
        this.closeByScenarios = [...this.closeByScenarios, ...scenarios];

        return scenarios;
      });
    }

    return Promise.resolve([]);
  };

  revertScenario(intl: IntlShape) {
    this.setScenarioData(this.originalScenarioData, intl, true);
  }

  getCloseByScenario(id: string): CrossAwarenessScenario {
    return this.closeByScenarios.find((s) => s.id === id);
  }

  setScenarioDataField(
    dataConfig: DataConfig[],
    fieldName: string,
    fieldValue: any,
    intl: IntlShape,
    inServerUnit?: boolean,
    noValidation = false,
  ) {
    const payload: any = { fieldName };
    const fieldConfig = getFieldConfig(fieldName, dataConfig);
    const diagnosticsConfig = getConfigSection(this.allStores.configStore.appConfig, 'diagnostics');

    if (fieldConfig) {
      // unit-conversion
      payload.dataValue = inServerUnit === false ? displayToField(fieldValue, fieldConfig) : fieldValue;
    } else {
      // no field-config, so unit-conversion is out of the question
      payload.dataValue = fieldValue;
    }

    // Use jsonpath to apply in a deep path approach
    jp.apply(this.scenarioData.data, `$.${fieldName}`, () => fieldValue);

    if (!noValidation) {
      // Must do FULL validation including any adjacent vessels, as changing bollards into green may mean
      // we need to also send another scenario green
      this.validateCloseByScenarios(this.closeByScenarios, diagnosticsConfig, intl);
    }
  }

  resetScenarioDataToDefaults(intl: IntlShape, portData: PortData, dataConfig: DataConfig[]) {
    const newContext = this.initialScenarioDataState;
    setDefaultValues(newContext.data, dataConfig, portData, intl);
    this.closeByScenarios = [];

    // If the tide type is range (2) we want to set the bin size to 0. Cannot change it in the dataConfig as we need the default value for when its not range
    if (newContext.data.environmentalConditions.tide.type === 2) {
      newContext.data.environmentalConditions.tide.binSize = 0;
    }

    this.validity = cloneDeep(this.initialValidityState);
    this.setScenarioData(newContext, intl, true);
  }

  handleValueChange(
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | null,
    dataConfig: DataConfig[],
    newValue?: any,
    fieldName?: string,
    intl?: IntlShape,
    validators?: Validator,
    noValidation = false,
  ) {
    const path = fieldName || (e && e.currentTarget.id);

    if (path) {
      this.setScenarioDataField(dataConfig, path, newValue, intl, false, noValidation);
    } else {
      throw new Error(
        'Missing `path` for `setScenarioDataField` (path can be obtained through `fieldName` or `e` argument)',
      );
    }
  }

  handleValuesChange(
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | null,
    dataConfig: DataConfig[],
    listFieldValue: ListFieldValue[],
    intl: IntlShape,
  ) {
    listFieldValue.forEach((itemFieldValue: ListFieldValue) => {
      this.handleValueChange(e, dataConfig, itemFieldValue.newValue, itemFieldValue.fieldName, intl);
    });
  }

  /**
   * This function expands the bollard indexes from relative to absolute indexes
   */
  expandBollardList = (scenario: VesselScenarioContainer) => {
    let errors = false;

    if (
      scenario.data?.port === undefined ||
      scenario.data.port?.bollards === undefined ||
      scenario.data.port.bollards.length === 0
    )
      return;

    scenario.data.mooring.mooringArrangements[scenario.data.mooring.mooringArrangementName].forEach((line) => {
      line.bollardIndex = this.allStores.portStore.allBollards.findIndex((portBollard) => {
        const scenarioBollard = scenario.data.port.bollards[line.bollardIndex];

        if (!scenarioBollard) {
          return false;
        }

        return (
          line.bollardIndex <= scenario.data.port.bollards.length - 1 &&
          largeRound(portBollard.point[0]) ===
            largeRound(scenario.data.port.bollards[line.bollardIndex].coordinates.x) &&
          largeRound(portBollard.point[1]) === largeRound(scenario.data.port.bollards[line.bollardIndex].coordinates.y)
        );
      });

      if (line.bollardIndex === -1) {
        console.error(`Could not find bollard for line (scenario: ${scenario.data.name})`, line);
        line.bollardIndex = 0; // set to 0, so it does not crash the app
        errors = true;
      }

      // Expand bollard list for shoretension system:
      if (line?.shoreTension) {
        line.shoreTension.bollardIndex = this.allStores.portStore.allBollards.findIndex((portBollard) => {
          const scenarioBollard = scenario.data.port.bollards[line.shoreTension.bollardIndex];

          if (!scenarioBollard) {
            return false;
          }

          return (
            line.shoreTension.bollardIndex <= scenario.data.port.bollards.length - 1 &&
            largeRound(portBollard.point[0]) ===
              largeRound(scenario.data.port.bollards[line.shoreTension.bollardIndex].coordinates.x) &&
            largeRound(portBollard.point[1]) ===
              largeRound(scenario.data.port.bollards[line.shoreTension.bollardIndex].coordinates.y)
          );
        });

        if (line.shoreTension.bollardIndex === -1) {
          console.error(`Could not find bollard for shore tension line (scenario: ${scenario.data.name})`, line);
          line.shoreTension.bollardIndex = 0; // set to 0, so it does not crash the app
          errors = true;
        }
      }
    });
  };

  /**
   * This function reduces the bollard indexes from absolute indexes to relative
   */
  reduceBollardList = (scenario: VesselScenarioContainer) => {
    // TODO There is a limitation here as the portBollards is just populated with the bollards thats relevant for the selected mooringArrangementName
    const scenarioClone = cloneDeep(scenario);

    let bollards = [];
    let bollardsUnused = cloneDeep(this.allStores.vesselScenarioStore.scenarioData.data.port.bollards);
    let bollardProfiles: BollardProfile[] = [];

    scenarioClone.data.mooring.mooringArrangements[scenarioClone.data.mooring.mooringArrangementName].forEach(
      (line) => {
        // Add bollards for each line:
        const portBollard = this.allStores.portStore.getConnectedBollard(line);

        if (portBollard) {
          const index = bollards.findIndex(
            (bollard) =>
              bollard.coordinates.x === portBollard.point[0] && bollard.coordinates.y === portBollard.point[1],
          );

          // Add it if its not already in the list
          if (index === -1) {
            bollards = [
              ...bollards,
              {
                coordinates: {
                  x: portBollard.point[0],
                  y: portBollard.point[1],
                  z: portBollard.point[2],
                },
                name: portBollard.name,
                profileId: portBollard.profileId,
              },
            ];

            const indexProfile = bollardProfiles.findIndex(
              (bollardProfile) => bollardProfile.bollardProfileId === portBollard.profileId!,
            );

            if (indexProfile === -1) {
              bollardProfiles = [
                ...bollardProfiles,
                this.allStores.portStore.portData.bollardProfiles.find(
                  (bollardProfile) => bollardProfile.bollardProfileId === portBollard.profileId!,
                ),
              ];
            }
          }
          line.bollardIndex = bollards.findIndex(
            (bollard) =>
              bollard.coordinates.x === portBollard.point[0] && bollard.coordinates.y === portBollard.point[1],
          );

          // Remove used bollards from 'unused' list:
          const indexUsed = bollardsUnused.findIndex(
            (bollard) =>
              bollard.coordinates.x === portBollard.point[0] && bollard.coordinates.y === portBollard.point[1],
          );
          if (indexUsed !== -1) {
            bollardsUnused.splice(indexUsed, 1);
          }
        }

        // Add bollards used by DynaMoor systems:
        if (line?.shoreTension) {
          const shoreTensionBollard = this.allStores.portStore.allBollards[line.shoreTension.bollardIndex];

          if (shoreTensionBollard) {
            const index = bollards.findIndex(
              (bollard) =>
                bollard.coordinates.x === shoreTensionBollard.point[0] &&
                bollard.coordinates.y === shoreTensionBollard.point[1],
            );

            // Add bollard to list if not already
            if (index === -1) {
              bollards = [
                ...bollards,
                {
                  coordinates: {
                    x: shoreTensionBollard.point[0],
                    y: shoreTensionBollard.point[1],
                    z: shoreTensionBollard.point[2],
                  },
                  name: shoreTensionBollard.name,
                  profileId: shoreTensionBollard.profileId,
                },
              ];

              const indexProfile = bollardProfiles.findIndex(
                (bollardProfile) => bollardProfile.bollardProfileId === shoreTensionBollard.profileId!,
              );

              if (indexProfile === -1) {
                bollardProfiles = [
                  ...bollardProfiles,
                  this.allStores.portStore.portData.bollardProfiles.find(
                    (bollardProfile) => bollardProfile.bollardProfileId === shoreTensionBollard.profileId!,
                  ),
                ];
              }
            }

            // Find index of shore tension bollard in new bollard list and set:
            line.shoreTension.bollardIndex = bollards.findIndex(
              (bollard) =>
                bollard.coordinates.x === shoreTensionBollard.point[0] &&
                bollard.coordinates.y === shoreTensionBollard.point[1],
            );
          }
        }
      },
    );

    scenarioClone.data.port.bollards = bollards;

    scenarioClone.data.port.bollardProfiles = bollardProfiles;

    scenarioClone.data.port.bollardsUnused = bollardsUnused;

    return scenarioClone;
  };

  /**
   * This function sets the winch
   * @param fairlead The fairlead of the line
   * @param newWinchId The winch id to change to of the line
   * @param line The line
   */
  changeWinchId = (fairlead: Fairlead, newWinchId: number, line: Line, lines: Line[]) => {
    fairlead.pathSelected = lineGroupKeys[line.lineType - 1];

    // First determine the correct path for the specific fairlead and then the connection points
    const path = fairlead.paths[fairlead.pathSelected];

    // Set the correct bittIndex
    if (newWinchId - 1 >= this.scenarioData.data.vessel.winches.length) {
      line.bittIndex = newWinchId - this.scenarioData.data.vessel.winches.length;

      // Set pretension to lower value when selecting to a bitt:
      line.pretension = this.scenarioData.data.mooring.linePretensionValue / 2;

      // Check other lines going to same fairlead and set them to the same bittIndex
      lines.forEach((l) => {
        if (l.id !== line.id && l.fairleadId === line.fairleadId && l.lineType === line.lineType) {
          l.bittIndex = line.bittIndex;
          l.pretension = line.pretension;
        }
      });
    } else if (line.bittIndex !== undefined) {
      line.bittIndex = undefined;
      line.pretension = this.scenarioData.data.mooring.linePretensionValue;
    }

    path.pedestalIds = []; // Reset pedestalIds
    path.winchId = newWinchId;
    path.winchConnectionPointId = 1;
  };

  /**
   * This function calculates line characteristics
   * @param scenario The scenario
   * @param fairlead The fairlead of the line
   * @param bollard The bollard of the line
   * @param lineType The line type
   * @param bollardInLocalCoordinates An indication of the bollard has already been converted to local coordinates or not
   */
  getLineCharacteristics = (
    scenario: VesselScenarioContainer,
    fairlead: Fairlead,
    bollard: Partial<AllBollard>,
    line: Line,
    bollardInLocalCoordinates = true,
  ) => {
    fairlead.pathSelected = lineGroupKeys[line.lineType - 1];

    let wlCorrection = 
      -getVesselMinOfDrafts(scenario.data.vessel) +
      (scenario.data.environmentalConditions.tide.type === 1
        ? scenario.data.environmentalConditions.tide.value
        : scenario.data.environmentalConditions.tide.max);
    wlCorrection = isNaN(wlCorrection) || wlCorrection === null  || wlCorrection === undefined ? 0 : wlCorrection; // Handling of a weird edge case where all z values were NaN - MA-2243.

    // First determine the correct path for the specific fairlead and then the connection points
    const selectedPath = lineGroupKeys[line.lineType - 1];
    const path = fairlead.paths[selectedPath];
    let winch: Winch;
    if (path.winchId - 1 >= scenario.data.vessel.winches.length) {
      const bitt = scenario.data.vessel.bitts.find(
        (bitt) => bitt.id === path.winchId - scenario.data.vessel.winches.length,
      );
      winch = {
        id: bitt.id,
        winchProfileId: bitt.bittProfileId,
        connectionPoints: [
          {
            id: 1,
            x: bitt.x,
            y: bitt.y,
            z: bitt.z,
          },
        ],
      };
    } else {
      winch = scenario.data.vessel.winches.find((winch) => winch.id === path.winchId);
    }
    const connectionPoint = winch.connectionPoints.find(
      (connectionPoint) => connectionPoint.id === path.winchConnectionPointId,
    );

    const pedestalFairleads = path.pedestalIds.map((pedestalId) => {
      const pedestalFairlead = scenario.data.vessel.pedestalFairleads.find(
        (pedestalFarilead) => pedestalFarilead.id === pedestalId,
      );

      return {
        x: pedestalFairlead.x,
        y: pedestalFairlead.y,
        z: pedestalFairlead.z + wlCorrection,
      } as Point3D;
    });

    // Setup the entire list of lines from winch through paths to fairlead
    const coordinates: Point3D[] = [
      { x: fairlead.x, y: fairlead.y, z: fairlead.z + wlCorrection } as Point3D,
      ...pedestalFairleads,
      {
        x: connectionPoint.x,
        y: connectionPoint.y,
        z: connectionPoint.z + wlCorrection,
      } as Point3D,
    ];

    // Then calculate the length of this line
    let fairleadWinchLength = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      fairleadWinchLength += Math.sqrt(
        (coordinates[i].x - coordinates[i + 1].x) * (coordinates[i].x - coordinates[i + 1].x) +
          (coordinates[i].y - coordinates[i + 1].y) * (coordinates[i].y - coordinates[i + 1].y) +
          (coordinates[i].z - coordinates[i + 1].z) * (coordinates[i].z - coordinates[i + 1].z),
      );
    }

    let [bollardX, bollardY] = bollard.point;

    if (!bollardInLocalCoordinates) {
      const berth: Berth = this.allStores.portStore.getBerth(scenario.data.mooring.berthName);
      const headingAngle = getHeadingAngle(scenario.data.mooring, berth);
      const vesselCentre = new UtmLatLon(scenario.data.mooring.latitude, scenario.data.mooring.longitude);

      const bollardCoordinates = latLngToUtm(
        [
          {
            x: bollardX,
            y: bollardY,
          },
        ],
        vesselCentre,
        headingAngle,
      );

      // eslint-disable-next-line prefer-destructuring
      [bollardX, bollardY] = bollardCoordinates[0];
    }

    const vX = bollardX - fairlead.x;
    const vY = bollardY - fairlead.y;
    const vZ = bollard.point[2] - (fairlead.z + wlCorrection);
    // From fairlead to bollard
    const lengthHorizontal = Math.sqrt(vX * vX + vY * vY);
    let verticalLineAngle = Math.abs((Math.atan2(vZ, lengthHorizontal) / Math.PI) * 180);
    let horizontalLineAngle = (Math.atan2(vY, vX) / Math.PI) * 180;
    horizontalLineAngle += horizontalLineAngle < 0 ? 360 : 0;

    // From winch to bollard
    let bollardFairleadLength = Math.sqrt(vX * vX + vY * vY + vZ * vZ);
    let totalLineLength = bollardFairleadLength + fairleadWinchLength;

    let horizontalLineAngleMin = scenario.data.mooring.portDock
      ? 360 - line.lineAngleHorizontalMax
      : line.lineAngleHorizontalMin;

    let horizontalLineAngleMax = scenario.data.mooring.portDock
      ? 360 - line.lineAngleHorizontalMin
      : line.lineAngleHorizontalMax;

    // rounding
    verticalLineAngle = maRound(verticalLineAngle);
    horizontalLineAngle = maRound(horizontalLineAngle);
    horizontalLineAngleMin = maRound(horizontalLineAngleMin);
    horizontalLineAngleMax = maRound(horizontalLineAngleMax);
    bollardFairleadLength = maRound(bollardFairleadLength);
    fairleadWinchLength = maRound(fairleadWinchLength);
    totalLineLength = maRound(totalLineLength);

    return {
      horizontalLineAngle,
      horizontalLineAngleMin,
      horizontalLineAngleMax,
      verticalLineAngle,
      bollardFairleadLength,
      fairleadWinchLength,
      totalLineLength,
    };
  };

  computeVesselFields = (
    dataConfig: DataConfig[],
    calculateInitialBerthMarker: boolean,
    diagnostics: Diagnostics,
    prescreeningCallback: (results: PrescreeningResult) => void,
  ) => {
    this.scenarioData.data.vessel.displacement = calculateDisplacement(this.scenarioData.data);
    this.scenarioData.data.vessel.freeboard = calculateFreeboard(this.scenarioData.data);

    const prescreeningResults = calculateBerthPrescreening(
      this.allStores.portStore,
      this.allStores.vesselScenarioStore,
      diagnostics,
    );
    let berth = null;

    const allowOverrideBerthPrescreening =
      this.allStores.configStore.appConfig.applicationSettings.allowOverrideBerthPrescreening;

    if (this.allStores.vesselScenarioStore.scenarioData.data.mooring.berthName != null) {
      if (
        !allowOverrideBerthPrescreening && // Only change berth if we do not allow overriding the prescreening results
        prescreeningResults[this.scenarioData.data.mooring.berthName] &&
        prescreeningResults[this.scenarioData.data.mooring.berthName].result === PrescreenIndicator.NotSafe
      ) {
        // Update appropriate validation for associated field
        const berthNameConfig = getFieldConfig('mooring.berthName', dataConfig);

        berthNameConfig.validationStatus = validateField(
          this.scenarioData.data.mooring.berthName,
          berthNameConfig,
          dataConfig,
          this.scenarioData.data,
        );

        this.allStores.appStateStore.showSnackbar('components.ma.forms.scenarioFormBerthWarning');

        berth = this.allStores.portStore.getBerth(this.scenarioData.data.mooring.berthName);
      } else {
        berth = this.allStores.portStore.getBerth(this.scenarioData.data.mooring.berthName);
      }
    }

    // Set all computed fields
    setDerivedValues(
      this.allStores.portStore,
      this.scenarioData,
      berth,
      (scenarioDataContainer: VesselScenarioContainer) => {},
    );

    if (calculateInitialBerthMarker) {
      if (!this.allStores.appStateStore.isNewMode) {
        calculateBerthMarkers(this, berth, 'mooring.berthMarker', this.scenarioData.data.mooring.berthMarker, () => {
          prescreeningCallback(prescreeningResults);
        });
      } else {
        prescreeningCallback(prescreeningResults);
      }
    } else {
      prescreeningCallback(prescreeningResults);
    }
  };

  setAllWinchProfiles = (winchProfileId: number) => {
    this.scenarioData.data.vessel.winches.forEach((winch) => {
      winch.winchProfileId = winchProfileId;
    });
  };

  setConstantTensionWinchProfile(
    constantTensionWinchProfile: ConstantTensionWinchProfile,
    insertAsNewTensionWinchProfile: boolean = false,
    removeTensionWinchId: boolean = false,
  ) {
    const { constantTensionWinchProfiles } = this.scenarioData.data.port;

    const maxTensionWinchProfileId = constantTensionWinchProfiles.reduce(
      (max, tensionProfile) => Math.max(tensionProfile.tensionWinchProfileId, max),
      0,
    );

    const existingProfileIndex = constantTensionWinchProfiles.findIndex(
      (tensionWinchProfile) =>
        tensionWinchProfile.tensionWinchProfileId === constantTensionWinchProfile.tensionWinchProfileId,
    );

    const profileAlreadyExists = existingProfileIndex >= 0;
    const shouldRemoveExistingProfile =
      profileAlreadyExists && (!insertAsNewTensionWinchProfile || removeTensionWinchId);

    if (shouldRemoveExistingProfile) {
      constantTensionWinchProfiles.splice(existingProfileIndex, 1);
      constantTensionWinchProfile.tensionWinchProfileId = !removeTensionWinchId
        ? constantTensionWinchProfile.tensionWinchProfileId
        : maxTensionWinchProfileId + 1;
    } else {
      constantTensionWinchProfile.tensionWinchProfileId = removeTensionWinchId
        ? constantTensionWinchProfile.tensionWinchProfileId
        : maxTensionWinchProfileId + 1;
    }

    // Add Tension Winch Profile:
    if (!removeTensionWinchId) {
      constantTensionWinchProfiles.push({ ...constantTensionWinchProfile });
    }

    return constantTensionWinchProfile.tensionWinchProfileId;
  }

  repairMooringLines = (addPortLines?: boolean) => {
    this.scenarioData.data.mooring.originalMooringArrangementNames = Object.keys(
      this.scenarioData.data.mooring.mooringArrangements,
    );

    // Set tail and line data for each mooring profile TODO: Get this done from fleet manager, redundant to do here I think?
    Object.keys(this.scenarioData.data.mooring.mooringArrangements).forEach((key) => {
      this.scenarioData.data.mooring.mooringArrangements[key].forEach((line, index) => {
        if (line.id === undefined || line.id === 0) {
          line.id = index + 1;
        }
        if (line.tail === undefined || line.tail === null) {
          line.tail = { tailProfileId: -1 } as Tail;
        }
      });
    });

    // Add port lines and shore tension lines into the data:
    if (addPortLines)
    {
      // Add port data Constant Tension Winch Line Profiles:
      let numberOfLinesProfiles = this.scenarioData.data.vessel.lineProfiles.length;
      if (
        this.allStores.portStore.portData.constantTensionWinchProfiles &&
        this.allStores.portStore.portData.constantTensionWinchLineProfiles &&
        this.allStores.portStore.portData.constantTensionWinchProfiles.length > 0
      ) {
        this.allStores.portStore.portData.constantTensionWinchLineProfiles.forEach((constantTensionWinchLineProfile, index) => {
          constantTensionWinchLineProfile.lineProfileId = numberOfLinesProfiles + index + 1;
          this.scenarioData.data.vessel.lineProfiles.push(constantTensionWinchLineProfile);
        });
      }

      // Add Port Specific line and tail profiles:
      numberOfLinesProfiles = this.scenarioData.data.vessel.lineProfiles.length;
      let numberOfTailProfiles = this.scenarioData.data.vessel.tailProfiles.length;
      if (this.allStores.portStore.portData.portLineProfiles && this.allStores.portStore.portData.portLineProfiles.length > 0) {
        this.allStores.portStore.portData.portLineProfiles.forEach((portLineProfile) => {
          if (portLineProfile.tail) 
          {
            numberOfTailProfiles++;
            const tailProfile: TailProfile = {
              ...portLineProfile,
              tailProfileId: numberOfTailProfiles,
              tailProfileName: portLineProfile.lineProfileName,
            };            
            this.scenarioData.data.vessel.tailProfiles.push(tailProfile);           
          } else {
            numberOfLinesProfiles++;
            portLineProfile.lineProfileId = numberOfLinesProfiles;
            this.scenarioData.data.vessel.lineProfiles.push(portLineProfile);
          }
        });
      }
    }
  };

  // Loop over each mooring arrangement and set the default pretension for each line
  setDefautlVaryingPretension(): void {
    const pretension = this.scenarioData.data.mooring.linePretensionValue;

    Object.keys(this.scenarioData.data.mooring.mooringArrangements).forEach((key) => {
      this.scenarioData.data.mooring.mooringArrangements[key].forEach((line: Line) => {
        // If no line pretension yet set it up:
        if (!line.pretension) {
          line.pretension = pretension;
        }
      });
    });
  }
}
